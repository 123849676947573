var Aneox;
(function (Aneox) {
  'use strict';

  var App = (function () {
    
    var self;
    
    var mainMenuVisibility;
    
    
    var currentNodeId;
    var currentKeyframeId;
    var currentNodeType;
    var navigationCounter;
    var legalVisibility;
    var legalPreviousNode;

    var menuId;

    var formToken;
    var isSubmittingForm = false;

    var vegetalVideo;
    var vinifVideo;

    var data_send = {};


    function App(fonts, locale, router, analytics, animations, wines) {
      this.fonts = fonts;
      this.locale = locale;
      this.router = router;
      this.analytics = analytics;
      this.animations = animations;
      this.wines = wines;

      this.data_send = data_send;
     

      //this.idFromVineyard = {};

      var viewportWidth, viewportHeight;
      var articles, previousScrollSectionHint, scrollSectionHint, scrollSectionHintNext, scrollSectionHintBackground;
      var endScrollMode, endScrollStart, endScrollOffset;


      self = this;

      lazySizesConfig.preloadAfterLoad = true;

      // Quick and dirty desktop/mobile check
      if (screen && screen.availWidth > 1024) window.location.href = 'https://www.domaine-kuheiji.fr';
      
      navigationCounter = 0;


      if (Cookies.get('acceptsSplashAge-mobile-kuheiji') === 'true'){
        $('#splash').remove();
        // $("#splash").modal('hide');
        $("body").removeClass('noscroll');
      }else{
        $("body").addClass('noscroll');
        // $("#splash").modal('show');
      }
        

      var navMenuList = $('#navMenu');
      var navMenu = $('.contentNav');

      var now = new Date();
      now.setFullYear(now.getFullYear() - 18);
      var nowText = now.toISOString().substring(0, 10).split('-');
      $('#age-check-date').text(nowText[2] + '/' + nowText[1] + '/' + nowText[0]);
      
      FastClick.attach(document.body);

      this.router.bind('pageChange', function (path, node, nodeLocale) {

        currentNodeId = node.id;
        currentKeyframeId = node.id;
        currentNodeType = node.type;

        if(node.id != "legal"){
            navigationCounter++;
        }

        if(navigationCounter > 1 && !Cookies.get('acceptsCookies-mobile-kuheiji')){
            setAcceptCookies();
            self.analytics.startAnalytics();
            TweenMax.set('#cookies',{ opacity: 0, display: 'none', onComplete: function(){
              $('#cookies').remove();
            }});
        }

        if (!Cookies.get('acceptsCookies-mobile-kuheiji')) { 
          TweenMax.to('#cookies', 0.5, { opacity: 1, display: 'block' });
        }else{
            if(Cookies.get('acceptsCookies-mobile-kuheiji') === 'true' && !self.analytics.analyticsEnabled){
                self.analytics.startAnalytics();
            }

            TweenMax.set('#cookies',{ opacity: 0, display: 'none', onComplete: function(){
              $('#cookies').remove();
            } });
        }

        self.locale.setLocale(nodeLocale.locale);

        $('.navFixed .burger').removeClass('black');

        switch (node.type) {
          case 'wines-page':
          case 'wine-page':
            menuId = "nos-vins";
            self.wines.show(node.id);
            $('section').not($('#splash')).remove();
            break;
          default:

          menuId = node.id;

            if (node.id === 'home' ) {
              $('section').not($('#home, #splash')).remove();
              $('.previous-section-hint').remove();
              previousScrollSectionHint = undefined;
            }
            else if(node.id === "millesimes"){
              $('section').not($('#vigne, #splash')).remove();
              $('.header').remove();
            }
            else{
              $('section').not($('#' + node.id+', #splash')).remove();
              $('.header').remove();

              if(node.id === "legal"){
                $('.navFixed .burger').addClass('black');
              }

            }

            $(window).on('touchstart', function () {
              var canScrollToTop = previousScrollSectionHint !== undefined && window.scrollY === 0;
              var canScrollToBottom = scrollSectionHint !== undefined && window.innerHeight + window.scrollY === document.body.offsetHeight;
              if (!canScrollToTop && !canScrollToBottom) return;

              endScrollMode = (window.scrollY === 0) ? 'top' : 'bottom';
              endScrollStart = event.touches[0].pageY;
            });

            $(window).on('touchmove', function () {
              if (endScrollStart === undefined) return;

              endScrollOffset = event.touches[0].pageY - endScrollStart;

              switch (endScrollMode) {
                case 'bottom':
                  if (endScrollOffset >= 0) {
                    endScrollStart = undefined;
                    endScrollOffset = undefined;
                    scrollSectionHint.css('top', '0');
                    scrollSectionHintNext.css('height', '0');
                    scrollSectionHintBackground.css('opacity', '1');
                  
                    return;
                  }
    
                  scrollSectionHint.css('top', endScrollOffset + 'px');
                  scrollSectionHintNext.css('height', -endScrollOffset + 'px');
                  scrollSectionHintBackground.css('opacity', Math.max(0, Math.min(1, 1 + endScrollOffset / viewportHeight)) + '');
    
                  break;
                case 'top':
                  if (endScrollOffset <= 0) {
                    endScrollStart = undefined;
                    endScrollOffset = undefined;
                    articles.css('top', '0');
                    return;
                  }
                  articles.css('top', Math.min(80, endScrollOffset) + 'px');
                  break;
              }

              event.preventDefault();
            });

            $(window).on('touchend', function () {
              if (endScrollStart === undefined || endScrollOffset === undefined) return;

              switch (endScrollMode) {
                case 'bottom':
                  if (Math.abs(endScrollOffset) < viewportHeight * 0.20) {
                    scrollSectionHintNext.animate({ height: 0 }, {
                      step: function (now, fx) {
                        scrollSectionHint.css({ "top": -now + "px" });
                        scrollSectionHintBackground.css('opacity', Math.max(0, Math.min(1, 1 - now / viewportHeight)) + '');
                      },
                      duration: 250,
                      easing: 'linear'
                    });
                  } else {
                    scrollSectionHintNext.animate({ height: viewportHeight }, {
                      step: function (now, fx) {
                        scrollSectionHint.css({ "top": -now + "px" });
                        scrollSectionHintBackground.css('opacity', Math.max(0, Math.min(1, 1 - now / viewportHeight)) + '');
                      },
                      duration: 250,
                      easing: 'linear',
                      complete: function () {
                        self.router.setPath(scrollSectionHint.attr('href'));
                      }
                    });
                  }
                  break;
                case 'top':
                  if (Math.abs(endScrollOffset) < 80) {
                    articles.animate({ top: 0 }, {
                      duration: 100,
                      easing: 'linear'
                    });
                  } else {
                    self.router.setPath(previousScrollSectionHint.attr('href'));
                  }
                  break;
              }

              endScrollStart = undefined;
              endScrollOffset = undefined;
              event.preventDefault();
            });

            break;
        }

        $('section').addClass('active');

        $('.navListe li a', navMenuList).removeClass('active');
        $('.navListe li a[router-href="' + currentKeyframeId + '"]', navMenuList).addClass('active');


        if(node.id === "legal")
        //$('#menu-bg-home').addClass('active');

        $(window).scrollTop(0);

        setTimeout(function () {
          // Window resize handling
          viewportWidth = Math.min($(window).width(), $(window).height());
          viewportHeight = Math.max($(window).width(), $(window).height());
    
          _.forEach($('[screen-height]'), function (element) {
            var sectionHeightElement = $(element);
            var multiplier = parseFloat(sectionHeightElement.attr('screen-height') || '1');
    
            var heightAdd = parseInt(sectionHeightElement.attr('height-add') || '0');

            sectionHeightElement.outerHeight(Math.round(viewportHeight * multiplier) + heightAdd);
          });
        
          _.forEach($('[height-set]'), function (element) {
            var sectionHeightElement = $(element);
            var height = parseInt(sectionHeightElement.attr('height-set') || '0');
            
            sectionHeightElement.outerHeight(height);
          });

          if(node.id === "philosophy"){
              $('html, body').scrollTop(window.innerHeight + 62);  
          }else if(node.id === "millesimes"){
              var sectionOffset = $('#vigne .a3').offset().top;
              $(window).scrollTop(sectionOffset);
          }else{
            $(window).scrollTop(0);
          }
      
          $('#preloader').addClass('discarded');
        }, 1000);
        setTimeout(function () { $('#preloader').remove(); }, 1000);

        articles = $('article');
        previousScrollSectionHint = $('.previous-section-hint');
        scrollSectionHint = $('.section-hint');
        scrollSectionHintNext = $('.section-hint-next');
        scrollSectionHintBackground = $('.section-hint-background');
      });

      this.locale.bind('localeChange', function (locale) {


         /* RE-BIND DATA LIST ON LOCAL CHANGE*/
         self.wines.setWinesList();

        $('.lang a').removeClass('active');
        $('.lang a[router-locale="' + locale + '"]').addClass('active');

        self.router.reparseAnchors();
      });

      self.router.init(true, locale.getLocale());
    }


    App.prototype.closeMenu = function(event){
      if (!mainMenuVisibility) return;
      mainMenuVisibility = false;

      $('body').removeClass('noscroll');
      TweenMax.to("#menu", .5, {opacity: 0, display: 'none'});
      if (event && event.preventDefault) event.preventDefault();
    }
      
    App.prototype.openMenu = function(event){
      if (mainMenuVisibility) return;
      mainMenuVisibility = true;
      
      $('body').addClass('noscroll');
      TweenMax.to("#menu", .5, {opacity: 1, display: 'block'});
      if (event && event.preventDefault) event.preventDefault();

    }

    App.prototype.toggleStickyMenu = function(){
      var stickyMenu = $('#sticky-nav');
      if(stickyMenu.hasClass('is-stiked')){
        TweenMax.to(stickyMenu, 0.5, {y:'-62px', onComplete: function(){
          stickyMenu.removeClass('is-stiked');
        }});
      }else{
        TweenMax.to(stickyMenu, 0.5, {y:'62px', onComplete: function(){
          stickyMenu.addClass('is-stiked');
        }});
      }
    }

     App.prototype.showLegal = function (event) {
       if (legalVisibility) return;
       legalVisibility = true;
       incrementScrollRetainCount();
       TweenMax.fromTo('#legal', 0.5, { display: 'block', opacity: 0 }, { opacity: 1 });
       if (event && event.preventDefault) event.preventDefault();
     };
    
     App.prototype.hideLegal = function (event) {
       if (!legalVisibility) return;
       legalVisibility = false;
       decrementScrollRetainCount();
      
       setPage.call(this, legalPreviousNode);
       TweenMax.fromTo('#legal', 0.5, { opacity: 1 }, { opacity: 0, display: 'none' });
       
     };
    
     App.prototype.ageCheckResult = function (result) {
       if (!result) return;
      
       $.cookie('acceptsSplashAge-mobile-kuheiji', false);
      //  $("#splash").modal('hide');
       TweenMax.to('#splash', 0.5, { opacity: 0, onComplete: function () {
         $('#splash').remove();
        } 
       });
     };
    
    App.prototype.primaryLanguageClick = function (event) {
      if (mainMenuVisibility) return;
      
      if (event && event.preventDefault) event.preventDefault();
      
      var newPath = this.router.getNodePath(currentNodeId, this.locale.getLocale() === "fr" ? "en" : "fr");
      this.router.setPath(newPath);
    };

    App.prototype.acceptCookies = function() {           
        setAcceptCookies();
        self.analytics.startAnalytics();
        TweenMax.to('#cookies', 0.5, { opacity: 0, display: 'none' });
    };
    
    App.prototype.deniedCookies = function(){
        setDeniedCookies();
        TweenMax.to('#cookies', 0.5, { opacity: 0, display: 'none' });
    }
    
    App.prototype.acceptSplashAge = function() {
        Cookies.set('acceptsSplashAge-mobile-kuheiji', 'true', { expires: 365 });
        $('#splash').addClass('accepts-spash-age');
        $("body").removeClass('noscroll');
        TweenMax.to('#splash', 0.5, { opacity: 0, display: 'none' });
        // $("#splash").modal('hide');
      
    };

  App.prototype.refuseSplashAge = function() {
      window.location.href = 'https://google.fr';
  };

  var setAcceptCookies = function(){
      Cookies.set('acceptsCookies-mobile-kuheiji', 'true' , { expires: 395 });
  }
  
  var setDeniedCookies = function(){
      Cookies.set('acceptsCookies-mobile-kuheiji', 'false', { expires: 7 });
  }

    return App;
  })();

  Aneox.App = App;
})(Aneox || (Aneox = {}));